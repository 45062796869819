<template>
  <b-modal id="modal-new-arquivo-remessa" centered hide-footer header-bg-variant="white">
    <div class="d-flex flex-column justify-content-center align-items-start px-1">
      <h3 class="mb-50">Subir arquivo de remessa</h3>
      <span class="mb-2"> Anexe o seu arquivo abaixo. </span>
      <b-row>
        <b-col cols="12">
          <v-select
            v-model="operacao"
            :options="operacoes"
            @input="onOperacaoChange"
            class="assignor-selector d-flex flex-column mr-2 mb-1"
            placeholder="Selecione uma operacao"
          />
          <v-select
            v-model="template"
            :options="templates"
            class="assignor-selector d-flex flex-column mr-2"
            placeholder="Escolha um template"
          />
          <cc-dropzone v-model="file" subtitle="" accept-formats=".txt, .REM, .xlsx, .csv" />
        </b-col>
      </b-row>

      <div class="d-flex flex-row justify-content-center mt-2 w-100">
        <b-button
          variant="outline-secondary"
          class="mb-1 mr-1 text-dark font-weight-bolder"
          @click="$bvModal.hide('modal-new-arquivo-remessa')"
        >
          Cancelar
        </b-button>
        <b-button
          variant="secondary"
          class="mb-1 bg-light-secondary font-weight-bolder"
          :disabled="!file"
          @click="uploadReturnFile"
        >
          Subir arquivo de remessa
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BButton, BRow, BCol } from 'bootstrap-vue'
import vSelect from 'vue-select'
import CcDropzone from '@/@core/components/cc-dropzone/CcDropzone.vue'
import http from '@/services/http'

export default {
  name: 'UploadReturnFileModal',
  components: {
    BRow,
    BCol,
    BModal,
    BButton,
    CcDropzone,
    vSelect,
  },
  data() {
    return {
      file: '',
      operacoesInfo: {},
      templates: [],
      template: '',
      operacoes: [],
      operacao: '',
    }
  },
  mounted() {
    this.getOperacoes()
  },
  methods: {
    async uploadReturnFile() {
      this.$swal({
        title: 'Confirmar upload?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        allowOutsideClick: false,
        preConfirm: async () => {
          this.$swal.showLoading()

          let formData = new FormData()

          formData.append('file', this.file)
          formData.append('template_id', this.template.value)
          formData.append('operacao_id', this.operacao.value)

          await http.post('/backoffice/importar_remessa', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })

          this.$bvModal.hide('modal-new-arquivo-remessa')
          this.$emit('uploaded')

          this.$swal({
            title: 'Sucesso!',
            text: 'Arquivo de retorno enviado com sucesso!',
            icon: 'success',
            confirmButtonText: 'Ok',
          })
        },
      })
    },
    acceptFormats() {
      [".RET, .TXT, .REM .xlsx`"]
    },
    getOperacoes() {
      http.get('/backoffice/operacoes')
      .then((response) => {
        this.operacoesInfo = response.data.data
        this.operacoes = response.data.data.map((operacao) => ({
          value: operacao.id,
          label: operacao.nome,
        }))
      })
    },

    onOperacaoChange() {
      if(this.operacao === '' || this.operacao === null) {
        this.template = ''
        this.templates = []
        return
      }

      this.templates = this.operacoesInfo.find(op => op.id === this.operacao.value).templates.map((template) => ({
        value: template.id,
        label: template.nome,
      }))
    },

  },
}
</script>
